import React from 'react';

function ShipImage() {
  return (
    <section className="ship-image">
      <img src="/assets/images/shippic.png
      " alt="Our Cruise Ship" />
    </section>
  );
}

export default ShipImage;